import { Middleware } from 'redux';
import { Services } from 'application/interfaces';
import {
  LOAD_COMPONENT_SUCCESS,
  LoadComponentSuccess,
} from '../../core/control/actions';
import {
  LoadShipmentData,
  setError,
  setLoading,
  setModuleLoaded,
  setResults,
  TRACKING_LOAD_DATA,
} from './actions';

export const LoadDataFlow = ({
  logger,
  api,
  platform,
}: Services): Middleware => ({
  dispatch,
}) => (next) => async (action: LoadShipmentData): Promise<void> => {
  next(action);
  if (action.type === TRACKING_LOAD_DATA) {
    if (!api?.shipments?.track) {
      logger.info('No trackShipments api service found');
      return;
    }
    if (!platform?.storage.getCookie) {
      logger.info('No getCookie method in platform.storage service found');
      return;
    }

    dispatch(setLoading(true));
    try {
      const userId = platform.storage.getCookie('_ga');
      const results = await api.shipments.track(action.payload, userId);
      dispatch(setResults(results));

      const isSingleNumberTracked = Object.keys(results).length === 1;
      const isSingleLinkReturned = Array.isArray(Object.values(results)[0])
        && Object.values(results)[0].length === 1;

      if (
        isSingleNumberTracked
        && isSingleLinkReturned
        && platform?.window.open
      ) {
        const shipment = Object.values(results)[0][0];

        platform.window.open(shipment.redirectTo);
      }
    } catch (e) {
      dispatch(setError(e.message));
      logger.info(e);
    }
    dispatch(setLoading(false));
  }
};

export const ModuleLoadedFlow = (): Middleware => ({
  dispatch,
}) => (next) => async (action: LoadComponentSuccess): Promise<void> => {
  next(action);
  if (
    action.type === LOAD_COMPONENT_SUCCESS
    && action.payload === 'tracking'
  ) {
    dispatch(setModuleLoaded(true));
  }
};

export default [
  LoadDataFlow,
  ModuleLoadedFlow,
];
